import React from 'react'
import Figure from './Figure'
import ExtraSmallFigure from './ExtraSmallFigure'
import SmallFigure from './SmallFigure'
import MediumFigure from './MediumFigure'
import LargeFigure from './LargeFigure'
import FullSizeFigure from './FullSizeFigure'
import TweetEmbed from './TweetEmbed'
import ArticleButton from './ArticleButton'

const serializers = {
  types: {
    authorReference: ({node}) => <span>{node.author.name}</span>,
    mainImage: Figure,
    extraSmallImage: ExtraSmallFigure,
    smallImage: SmallFigure,
    mediumImage: MediumFigure,
    largeImage: LargeFigure,
    fullSizeImage: FullSizeFigure,
    tweetEmbed: TweetEmbed,
    articleButton: ArticleButton
  },
  marks: {
    link: ({mark, children}) => {
      // Read https://css-tricks.com/use-target_blank/
      const { blank, href } = mark
      return blank ?
        <a href={href} target="_blank" rel="noopener">{children}</a>
        : <a href={href}>{children}</a>
    }
  }
}

export default serializers
