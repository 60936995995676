import React from 'react'
import Img from 'gatsby-image'
import {getFluidGatsbyImage} from 'gatsby-source-sanity'
import clientConfig from '../../client-config'
import styled from 'styled-components'

const ImageWrapper = styled.div`
  width: 400px;
`

export default ({node}) => {
  if (!node || !node.asset || !node.asset._id) { return null }
  console.log(node)

  const fluidProps = getFluidGatsbyImage(
    node.asset._id,
    {maxWidth: 400},
    clientConfig.sanity
  )
  return (
    <ImageWrapper>
        <Img fluid={fluidProps} alt={node.alt} />
        <figcaption>{node.caption}</figcaption>
    </ImageWrapper>
  )
}
