import React from 'react'
import clientConfig from '../../client-config'
import styled from 'styled-components'
import { TwitterTimelineEmbed,
  TwitterShareButton,
  TwitterFollowButton,
  TwitterHashtagButton,
  TwitterMentionButton,
  TwitterTweetEmbed,
  TwitterMomentShare,
  TwitterDMButton,
  TwitterVideoEmbed,
  TwitterOnAirButton
} from 'react-twitter-embed';

const Wrapper = styled.div`
  width: 100%;
`

export default ({node}) => {
  if (!node.tweetId) { return null }
  console.log(node)

  // const fluidProps = getFluidGatsbyImage(
  //   node.asset._id,
  //   {maxWidth: 1000},
  //   clientConfig.sanity
  // )

  return (
    <Wrapper>
        <TwitterTweetEmbed tweetId={node.tweetId} />
    </Wrapper>
  )
}
