import React from 'react'
import styled from 'styled-components'
import ActionButton from "./ActionButton"

export default ({node}) => {
  if (!node || !node.buttonURL) { return null }
  console.log(node)


  return (
    <a href={node.buttonURL} target="_blank">
      <ActionButton content={node.buttonText} />
    </a>
  )
}
